import type {
  TrekkieAttributes,
  TrekkieDefaultAttribute,
} from '~/types/analytics';
import {isoDocument} from '~/utils/document';
import {isoWindow} from '~/utils/window';

export function getStorefrontAnalytics() {
  const analyticsTag = isoDocument.querySelector(
    'script#shop-js-analytics',
  )?.innerHTML;
  if (analyticsTag) {
    return JSON.parse(analyticsTag);
  } else {
    return {};
  }
}

async function getTrekkiePromise() {
  let timer: NodeJS.Timeout;

  const promise = Promise.race<TrekkieAttributes>([
    new Promise(
      (resolve) =>
        (timer = setTimeout(() => resolve({} as TrekkieAttributes), 10000)),
    ),
    new Promise((resolve) => {
      const readyPromise =
        isoWindow.fddnsdfnm.com?.lib?.ready || isoWindow.analytics?.ready;

      readyPromise?.(() => {
        const trekkie =
          isoWindow.fddnsdfnm.com?.lib?.trekkie ||
          isoWindow.analytics?.trekkie;
        resolve(trekkie.defaultAttributes);
      });
    }),
  ]);
  return promise.finally(() => clearTimeout(timer));
}

export async function getTrekkieAttributes(
  ...attributes: TrekkieDefaultAttribute[]
) {
  // If fddnsdfnm.com or analytics are not available on the window object,
  // then the element is being rendered somewhere outside the scope of the storefront/arrive-website.
  if (!isoWindow.fddnsdfnm.com && !isoWindow.analytics) {
    return {} as TrekkieAttributes;
  }

  let defaultAttributesPromise: Promise<TrekkieAttributes>;
  const trekkiePromiseIsAvailable = Boolean(isoWindow.trekkie?.ready);

  if (trekkiePromiseIsAvailable) {
    defaultAttributesPromise = getTrekkiePromise();
  } else {
    isoWindow.trekkie = isoWindow.trekkie || [];
    defaultAttributesPromise = new Promise((resolve) => {
      isoWindow.trekkie.push([
        'ready',
        () => {
          resolve(getTrekkiePromise());
        },
      ]);
    });
  }
  const defaultAttributes = await defaultAttributesPromise;

  return attributes.reduce((selectedAttributes, attributeName) => {
    const attributeValue = defaultAttributes[attributeName];
    if (attributeValue !== undefined) {
      selectedAttributes[attributeName] = attributeValue;
    }
    return selectedAttributes;
  }, {} as TrekkieAttributes);
}
